import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import {GoogleMap} from "../components/GoogleMap";
import {MyMapComponent} from "../components/GoogleMap";
import MyFancyComponent from "../components/GoogleMap";

export const AboutPageTemplate = ({title, sections, contentComponent}) => {
    const PageContent = contentComponent || Content
    return (
        <React.Fragment>
            <section className="section section--gradient mid-green-bg">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="has-text-centered">
                                <h2 className="title is-size-3 is-ligna-font font-white is-uppercase">
                                    /&nbsp;{title}&nbsp;/
                                </h2>
                                {/*<PageContent className="content" content={content} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {_.map(sections, (section, index) => {
                let hasLightBg = index % 2 === 0;
                const map = section.title === 'Our Location' ? <div className={'my-3 mx-mobile-n2'}><MyFancyComponent
                    hasInfoBox={false}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElementHeight={'400px'}
                    mapElement={<div style={{ height: `100%` }} />}
                /></div> : '';
                return <section
                    className={"section border-top-primary" + (hasLightBg ? ' light-green-bg' : ' mid-green-bg')}>
                    <div className="container">
                        <div className={'columns'}>
                            <div className={'column is-3 is-one-third-desktop my-auto'}>
                                <div className={'section-title-container'}>
                                    {/*<div className={'section-title-icon'}>*/}
                                    {/*<i className={section.icon}></i>*/}
                                    {/*</div>*/}
                                    <div className={'icon-circle-container'}>
                                        <div className={'icon-circle '}>
                                            <i className={section.icon}></i>
                                        </div>
                                    </div>
                                    <div
                                        className={'section-title-icon has-text-centered' + (hasLightBg ? ' font-primary' : ' font-white')}>
                                        <h4>{section.title}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className={'column is-9 is-two-thirds-desktop'}>
                                <div className={'card'}>
                                    <div className={'card-header font-primary is-ligna-font p-0'}>
                                        <h3>{section.tagline}</h3>
                                    </div>
                                    <div className={'column is-3 no-padding'}>
                                        <hr/>
                                    </div>
                                    <p className={'card-first-para'}>{section.firstPara}</p>
                                    {map}
                                    <p className={'section-body'}>{section.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>;
            })}
        </React.Fragment>
    )
}

AboutPageTemplate.propTypes = {
    // title: PropTypes.string.isRequired,
    // content: PropTypes.string,
    // contentComponent: PropTypes.func,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const AboutPage = ({data}) => {
    // const {markdownRemark: post} = data
    const {frontmatter} = data.markdownRemark
    console.log(frontmatter.sections);

    return (
        <Layout>
            <AboutPageTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                sections={frontmatter.sections}
            />
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        sections {
            title
            tagline
            icon
            firstPara
            text
        }
      }
    }
  }
`
